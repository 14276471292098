import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Moment from 'react-moment';
import PostDate from './postdate';
import { Helmet } from "react-helmet"

import Img from "gatsby-image"
import "./blog-post.scss"


export default ({ data }) => {
  const post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post.frontmatter.title}</title>
        <link rel="canonical" href={"https://ervinebalo.com" + post.fields.slug} />
      </Helmet>
      <div className="blogPost">
        <div className="featuredImage">
          <Img fluid={featuredImgFluid}/>
        </div>
        <h1 className="title">{post.frontmatter.title}</h1>
        <h3 className="subtitle">{post.frontmatter.description}</h3>
        <div className="postmetadata">
          <PostDate data={post.frontmatter.date}/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`