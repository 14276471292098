import React from "react"
import Moment from 'react-moment';


export default ({ data }) => {

  return (
    <span>
      Posted on <span className="updateNamed"><Moment format="MMM DD, YYYY">{data}</Moment></span>
    </span>
    
  )
}